













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VueCropper from 'vue-cropperjs';

@Component({ components: { VueCropper } })
export default class Cropper extends Vue {
  @Prop({}) private data!: any;
  @Prop({}) confirm!: any;
  private isReady = false;
  private imgSrc: string | null = null;
  public created() {
    if (this.data && this.data.img) {
      this.imgSrc = this.data.img;
    }
  }

  public zoom(percent:number) {
     (this.$refs.cropper as any).relativeZoom(percent);
   }

  @Watch('confirm')
  private handleConfirm() {
    //@ts-ignore
    this.$modal.$emit('confirm', this.$refs.cropper.getCroppedCanvas().toDataURL());
  }
}
